import { useBotStore, useUserStore } from "~/stores";

export function useRequestParams(): {
  botCode: string;
  tenantId: string;
  tenantUnitId: string;
};
export function useRequestParams(agentIdRequired: false): {
  botCode: string;
  tenantId: string;
  tenantUnitId: string;
};
export function useRequestParams(agentIdRequired: true): {
  agentId: string;
  botCode: string;
  tenantId: string;
  tenantUnitId: string;
};

export function useRequestParams(agentIdRequired?: boolean) {
  const { info } = useUserStore();
  const { getBotCode } = useBotStore();

  const params: {
    agentId?: string;
    botCode: string;
    tenantId: string;
    tenantUnitId: string;
  } = {
    botCode: getBotCode(),
    tenantId: info.tenantId,
    tenantUnitId: info.tenantUnitId,
  };

  if (agentIdRequired) {
    params.agentId = info.agentId;
  }

  return params;
}
