import { FunctionComponent } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import classNames from "classnames";
import { useBus } from "react-bus";
import Markdown from "markdown-to-jsx";
import { Product } from "~/types/product";
import CheckSVG from "~/assets/icons/check.svg?react";
import { useBotStore } from "~/stores";
import { CompanionCardType } from "~/types/shoppingCompanion";
import arrowSVG from "~/assets/icons/arrow_02.svg?react";
import Icon from "@ant-design/icons";
import { isMobileMode } from "~/utils/host";

import "./styles.scss";

interface IProductGroupProps {
  products: Product[];
  summary: string;
  cardType: CompanionCardType;
}

const CompanionProductCard: FunctionComponent<IProductGroupProps> = ({
  products,
  summary,
  cardType,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<string>(products[0]?.id);
  const { removeCompareData, pushCompareData, compareData, toggleCompareDialog } = useBotStore();

  if (!products?.length) {
    return null;
  }

  if (cardType === CompanionCardType.Featured) {
    const selectedProduct = products.find((p) => p.id === selected);
    const isChecked = compareData.some((c) => c.product_id === selectedProduct?.id);
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: selectedProduct?.productVariants[0].priceInfo?.currencyCode ?? "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return (
      <div className="companion-product-message">
        <div className="companion-product-featured">
          <div className="companion-product-summary">
            <Markdown
              className="markdown-body is-bot"
              options={{
                wrapper: "div",
                forceWrapper: true,
              }}
            >
              {summary}
            </Markdown>
          </div>
          <div className="companion-product-featured-selected">
            <div
              className={classNames("checkbox", { checked: isChecked })}
              onClick={() => {
                if (isChecked) {
                  removeCompareData(selectedProduct?.id as string);
                } else {
                  pushCompareData({
                    product_id: selectedProduct?.id,
                    product_images: selectedProduct?.productVariants[0].productVariantImages,
                  });
                }
              }}
            >
              <span className="inner">{isChecked && <CheckSVG />}</span>
              <img
                className="product-cover"
                src={selectedProduct?.productVariants[0].productVariantImages[0]?.url}
              />
            </div>
            <div
              className="product-info-box"
              onClick={() => {
                window.open(selectedProduct?.productUrl, "_blank");
              }}
            >
              <div>
                <div className="title">{selectedProduct?.name}</div>
                <div className="price-box">
                  <span className="latest-price">
                    {formatter.format(selectedProduct?.productVariants[0].priceInfo?.price ?? 0)}
                  </span>
                  <span className="original-price">
                    {formatter.format(
                      selectedProduct?.productVariants[0].priceInfo?.compareAtPrice ?? 0
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="companion-product-list">
          <div>
            {products.map((product) => {
              return (
                <img
                  key={product.id}
                  src={product.productVariants[0].productVariantImages[0]?.url}
                  className={classNames("product-thumbnail", {
                    active: selected === product.id,
                    selected: compareData.some((c) => c.product_id === product.id),
                  })}
                  onClick={() => {
                    setSelected(product.id);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  if (cardType === CompanionCardType.Column) {
    return (
      <div className="companion-product-message column-type">
        <div className="companion-product-column">
          <div className="companion-product-summary">
            <Markdown
              className="markdown-body is-bot"
              options={{
                wrapper: "div",
                forceWrapper: true,
              }}
            >
              {summary}
            </Markdown>
          </div>
          {products.map((product) => {
            const isChecked = compareData.some((c) => c.product_id === product?.id);
            const formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: product?.productVariants[0].priceInfo?.currencyCode ?? "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return (
              <div className="companion-product-column-selected">
                <div
                  className={classNames("checkbox", { checked: isChecked })}
                  onClick={() => {
                    if (isChecked) {
                      removeCompareData(product?.id as string);
                    } else {
                      pushCompareData({
                        product_id: product?.id,
                        product_images: product?.productVariants[0].productVariantImages,
                      });
                    }
                  }}
                >
                  <span className="inner">{isChecked && <CheckSVG />}</span>
                  <img
                    className="product-cover"
                    src={product?.productVariants[0].productVariantImages[0]?.url}
                  />
                </div>
                <div
                  className="product-info-box"
                  onClick={() => {
                    window.open(product?.productUrl, "_blank");
                  }}
                >
                  <div>
                    <div className="title">{product?.name}</div>
                    <div className="price-box">
                      <span className="latest-price">
                        {formatter.format(product?.productVariants[0].priceInfo?.price ?? 0)}
                      </span>
                      <span className="original-price">
                        {formatter.format(
                          product?.productVariants[0].priceInfo?.compareAtPrice ?? 0
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  if (cardType === CompanionCardType.Row) {
    return (
      <div className="companion-product-message row-type">
        <div className="companion-product-summary">
          <Markdown
            className="markdown-body is-bot"
            options={{
              wrapper: "div",
              forceWrapper: true,
            }}
          >
            {summary}
          </Markdown>
        </div>
        <div className="companion-product-row" ref={ref}>
          {products.map((product) => {
            const formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: product?.productVariants[0].priceInfo?.currencyCode ?? "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return (
              <div
                className="companion-product-row-item"
                onClick={() => {
                  window.open(product?.productUrl, "_blank");
                }}
              >
                <img
                  className="product-cover"
                  src={product?.productVariants[0].productVariantImages[0]?.url}
                />
                <div className="product-info-box">
                  <div className="title">{product?.name}</div>
                  <div className="price-box">
                    <span className="latest-price">
                      {formatter.format(product?.productVariants[0].priceInfo?.price ?? 0)}
                    </span>
                    <span className="original-price">
                      {formatter.format(product?.productVariants[0].priceInfo?.compareAtPrice ?? 0)}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="action-bar">
          <Icon
            className="prev"
            component={arrowSVG}
            onClick={() => {
              ref.current?.scrollBy({ left: -ref.current.clientWidth, behavior: "smooth" });
            }}
          />
          <Icon
            className="next"
            component={arrowSVG}
            onClick={() => {
              ref.current?.scrollBy({ left: ref.current.clientWidth, behavior: "smooth" });
            }}
          />
        </div>
        <div
          className={classNames("view-detail", { mobile: isMobileMode })}
          onClick={() => {
            pushCompareData(
              products.map((product) => ({
                product_id: product.id,
                product_images: product.productVariants[0].productVariantImages,
              }))
            );

            toggleCompareDialog();
          }}
        >
          View Details
        </div>
      </div>
    );
  }
};

export default CompanionProductCard;
