import {
  useBotStore,
  useCustomizeStore,
  useMessageStore,
  useShoppingCompanionStore,
  useUserStore,
} from "~/stores";
import {
  findFirstSelectedActiveCode,
  getCouponCompanionByPosition,
} from "~/utils/shoppingCompanion";
import { useShoppingCompanion } from "~/hooks/useShoppingCompanion";
import useCopyToClipboard from "~/hooks/useCopyToClipboard";
import { useState } from "preact/hooks";
import { postEmail } from "~/api/shoppingCompanion";
import { CompanionCardType, PopConfigType } from "~/types/shoppingCompanion";
import { createMessage } from "~/api/chat";
import { getConversationId } from "./message/utils";

export const useCouponCard = (position: CompanionCardType) => {
  const {
    customize: { brandColor },
  } = useCustomizeStore();
  const { toggleBotVisibilityInWidgetMode, getBotCode } = useBotStore();
  const botCode = getBotCode();
  const {
    info: { agentId, tenantUnitId, tenantId },
  } = useUserStore();
  const { shoppingCompanions } = useShoppingCompanionStore();
  const { appendMessage } = useMessageStore();
  const [email, setEmail] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const couponCompanion = getCouponCompanionByPosition(shoppingCompanions, position);
  const currentCoupon =
    couponCompanion && couponCompanion.popConfig.type === PopConfigType.Coupon
      ? findFirstSelectedActiveCode(couponCompanion.popConfig.config.discountCodes)
      : null;

  const { isVisible, handleHide } = couponCompanion
    ? useShoppingCompanion(couponCompanion)
    : { isVisible: false, handleHide: () => {} };

  const { copyToClipboard, isCopied } = useCopyToClipboard();
  const requiresEmailCollection =
    couponCompanion?.popConfig?.type === PopConfigType.Coupon
      ? couponCompanion.popConfig.config.leads.collectEmail
      : false;

  // for fullScreen and bottom coupon
  const handleCouponAction = async () => {
    if (!currentCoupon) return;

    if (requiresEmailCollection && !emailSubmitted) {
      setLoading(true);
      try {
        const response = await postEmail(botCode, tenantUnitId, email);
        if (response) {
          setEmailSubmitted(true);
        } else {
          setErrorMsg("Failed to submit email");
        }
      } catch (error) {
        setErrorMsg("An error occurred while submitting your email. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      // Handle direct copy flow
      copyToClipboard(currentCoupon.code);
    }
  };

  const usageInstructions =
    couponCompanion?.popConfig?.type === PopConfigType.Coupon
      ? couponCompanion.popConfig.config.usageInstructions
      : "";

  // different workflow for botAreaCoupon
  const handleCouponClickBot = async (showCode: boolean) => {
    if (!currentCoupon) return;
    if (requiresEmailCollection && !showCode) {
      handleHide();
      const conversationId = await getConversationId(botCode);
      createMessage(conversationId, botCode, {
        tenantId,
        tenantUnitId,
        agentId,
        content: "",
        isBot: true,
        messageFrom: "Bot",
        type: "EmailCollection",
        conversationId,
        metadata: {},
      }).then((res) => {
        res.isBot = true;
        appendMessage(res);
      });
      toggleBotVisibilityInWidgetMode?.();
    } else {
      copyToClipboard(currentCoupon.code);
    }
  };

  const handleEmailSubmitBot = async (value: any) => {
    setEmail(value.email);
    try {
      const conversationId = await getConversationId(botCode);
      const emailReplyUser = await createMessage(conversationId, botCode, {
        tenantId,
        tenantUnitId,
        agentId,
        content: value.email,
        isBot: false,
        type: "text",
        messageFrom: "User",
        text: value.email,
        conversationId,
        metadata: {},
      });
      emailReplyUser.isBot = false;
      emailReplyUser.text = value.email;
      appendMessage(emailReplyUser);

      const response = await postEmail(botCode, tenantUnitId, value.email);
      if (response) {
        const thankYouMessage = await createMessage(conversationId, botCode, {
          tenantId,
          tenantUnitId,
          agentId,
          content: "Thank you for submitting your email. Please claim your coupon",
          isBot: true,
          messageFrom: "Bot",
          type: "text",
          text: "Thank you for submitting your email. Please claim your coupon",
          conversationId,
          metadata: {},
        });
        thankYouMessage.isBot = true;
        thankYouMessage.text = "Thank you for submitting your email. Please claim your coupon";
        appendMessage(thankYouMessage);

        const couponWithCode = await createMessage(conversationId, botCode, {
          tenantId,
          tenantUnitId,
          agentId,
          content: "",
          isBot: true,
          messageFrom: "Bot",
          type: "Coupon",
          conversationId,
          metadata: {
            resource: {
              coupon: {
                showCode: true,
              },
            },
          },
        });
        couponWithCode.isBot = true;
        couponWithCode.metadata = {
          resource: {
            coupon: {
              showCode: true,
            },
          },
        };
        appendMessage(couponWithCode);
      } else {
        setErrorMsg("Failed to submit email");
      }
    } catch (error) {
      setErrorMsg("An error occurred while submitting your email. Please try again.");
    }
  };

  return {
    brandColor,
    couponCompanion,
    currentCoupon,
    isVisible,
    handleHide,
    isCopied,
    handleCouponAction,
    requiresEmailCollection,
    email,
    setEmail,
    emailSubmitted,
    setEmailSubmitted,
    loading,
    errorMsg,
    handleCouponClickBot,
    handleEmailSubmitBot,
    usageInstructions,
  };
};
