import { generate } from "@ant-design/colors";
import { hexToRGBA } from ".";
import { isEqual, omit } from "lodash-es";
import { BotWidgetOptionalProps } from "~/BotWidget/register";

export interface BotConfigProps extends BotWidgetOptionalProps {
  code: string;
  _shadowRoot?: HTMLElement;
  event_to_document?: string;
  eventToDocument?: boolean;
  force_widget?: string;
  forceWidget?: boolean;
  force_render?: "pc" | "mobile" | "widget";
  forceRender?: "pc" | "mobile" | "widget";
  hide_loading_n_error?: string;
  hideLoadingNError?: boolean;
  draggable?: string | boolean;
  externaluserid?: string;
  productid?: string;
}

export function validateProps(props: BotConfigProps): BotConfigProps {
  const parseStyleNumber = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      const percentageMatch = /^\d+(\.\d+)?%$/.exec(value);
      if (percentageMatch) {
        return parseFloat(percentageMatch[0]) / 100;
      }
      const numberMatch = /^\d+(\.\d+)?$/.exec(value);
      if (numberMatch) {
        return parseFloat(numberMatch[0]);
      }
    }
    return undefined;
  };

  const isValidColor = (color: string) => {
    const hexPattern = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
    const rgbPattern = /^rgb\(\s*(\d{1,3}\s*,\s*){2}\d{1,3}\s*\)$/;
    return hexPattern.test(color) || rgbPattern.test(color);
  };

  const parseStringBoolean = (value: boolean | string | undefined, defaultValue?: boolean) =>
    value === "false" ? false : value ? Boolean(value) : defaultValue;
  const parseBoolean = (
    camelProps: boolean | undefined,
    dashProps: string | undefined,
    defaultValue?: boolean
  ) => (camelProps ? Boolean(camelProps) : parseStringBoolean(dashProps, defaultValue));

  return {
    ...props,
    w: props.w !== undefined ? parseStyleNumber(props.w) : undefined,
    h: props.h !== undefined ? parseStyleNumber(props.h) : undefined,
    x: props.x !== undefined ? parseStyleNumber(props.x) : undefined,
    y: props.y !== undefined ? parseStyleNumber(props.y) : undefined,
    theme: props.theme !== undefined && isValidColor(props.theme) ? props.theme : undefined,
    forceWidget: parseBoolean(props.forceWidget, props.force_widget),
    eventToDocument: parseBoolean(props.eventToDocument, props.event_to_document),
    forceRender: props.forceRender ? props.forceRender : props.force_render,
    hideLoadingNError: parseBoolean(props.hideLoadingNError, props.hide_loading_n_error),
    draggable: parseStringBoolean(props.draggable, "draggable" in props ? true : undefined),
    externalUserId: props.externaluserid,
    productId: props.productId ?? props.productid,
  };
}

const OfficialBrandColor = "#00B395";

export function generatePalettes(brandColor: string) {
  const palettes =
    brandColor === OfficialBrandColor
      ? [
          "#ecfefb",
          "#beefe7",
          "#85e0d1",
          "#17cfb1",
          "#00c3a4",
          "#00b395",
          "#00846f",
          "#005f4f",
          "#003732",
          "#001917",
        ]
      : generate(brandColor);

  const realPalettes = Array.from(new Set(palettes));

  if (realPalettes.length < 10) {
    palettes[1] = hexToRGBA(palettes[1], 0.1);
    palettes[2] = hexToRGBA(palettes[2], 0.15);
  }

  return palettes;
}

export function generateStyleVariables(
  props: BotConfigProps,
  brandColor: string,
  palettes: string[]
) {
  const { x, y, w, h } = props;

  return {
    "--x": typeof x === "number" ? (x > 1 ? `${x}px` : `${x * 100}vw`) : "calc(100vw - 32px)",
    "--y": typeof y === "number" ? (y > 1 ? `${y}px` : `${y * 100}vh`) : "calc(100vh - 24px)",

    "--w": typeof w === "number" ? (w > 1 ? `${w}px` : `${w * 100}vw`) : "500px",

    "--min-h": "300px",
    "--default-h": "700px",
    "--h": typeof h === "number" ? (h > 1 ? `${h}px` : `${h * 100}vh`) : "var(--default-h)",

    "--bubble-margin": "16px",
    "--bubble-size": "60px",
    "--primary-color": brandColor,
    "--primary-color-1": palettes[0],
    "--primary-color-2": palettes[1],
    "--primary-color-3": palettes[2],
    "--primary-color-6": palettes[5],
    "--primary-color-7": palettes[6],
  };
}

export function propsEqual(prevProps: BotConfigProps | undefined, nextProps: BotConfigProps) {
  if (prevProps === undefined) {
    return false;
  }
  const staticProps = [
    "_shadowRoot",
    "event_to_document",
    "eventToDocument",
    "force_widget",
    "forceWidget",
    "force_render",
    "forceRender",
    "draggable",
  ];
  return isEqual(omit(prevProps, staticProps), omit(nextProps, staticProps));
}
