import { type StateCreator } from "zustand";
import { Product } from "~/types/product";
import { ShoppingCompanion } from "~/types/shoppingCompanion";

export interface ShoppingCompanionStore {
  shoppingCompanions: ShoppingCompanion[];
  updateShoppingCompanions: (companions: ShoppingCompanion[]) => void;

  recommendProducts: Product[];
  updateRecommendProducts: (products: Product[]) => void;

  pendingQuestion: string;
  updatePendingQuestion: (question: string) => void;
}

const shoppingCompanionStore: StateCreator<ShoppingCompanionStore> = (set, get) => ({
  recommendProducts: [],
  shoppingCompanions: [],
  pendingQuestion: "",

  updateShoppingCompanions: (companions: ShoppingCompanion[]) => {
    set((state) => ({
      ...state,
      shoppingCompanions: [...state.shoppingCompanions, ...companions],
    }));
  },

  updateRecommendProducts(products: Product[]) {
    set((state) => ({
      ...state,
      recommendProducts: products,
    }));
  },

  updatePendingQuestion(question) {
    set((state) => ({
      ...state,
      pendingQuestion: question,
    }));
  },
});

export default shoppingCompanionStore;
