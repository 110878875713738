import { useBotStore, useShoppingCompanionStore } from "~/stores";
import { PopConfigType } from "~/types/shoppingCompanion";
import "./styles.scss";

const TextBubbleCard = () => {
  const { shoppingCompanions, updatePendingQuestion } = useShoppingCompanionStore();
  const { toggleBotVisibilityInWidgetMode } = useBotStore();

  if (shoppingCompanions[0].popConfig.type !== PopConfigType.Text) {
    return null;
  }

  const config = shoppingCompanions[0].popConfig.config;

  return (
    <div>
      {config.questions.slice(0, 3).map((question, index) => {
        return (
          <div
            className="text-bubble"
            onClick={() => {
              updatePendingQuestion(question.content);
              toggleBotVisibilityInWidgetMode?.();

              config.questions.splice(index, 1);
            }}
          >
            {"title" in question && <div className="text-bubble-title">{question.title}</div>}
            <div className="text-bubble-desc">{question.content}</div>
          </div>
        );
      })}
    </div>
  );
};

export default TextBubbleCard;
