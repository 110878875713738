import { ShoppingCompanion } from "~/types/shoppingCompanion";
import request from "~/utils/request";

export const getActiveShoppingCompanion = (
  tenantUnitId: string,
  botCode: string,
  status: string = "active"
): Promise<ShoppingCompanion[]> =>
  request({
    url: "/shoppingCompanions",
    params: {
      tenantUnitId,
      status,
      botCode,
    },
  });

// post email
export const postEmail = (botCode: string, tenantUnitId: string, email: string): Promise<any> =>
  request({
    method: "post",
    url: `/users`,
    params: {
      botCode,
    },
    data: {
      tenantUnitId,
      email,
    },
  });
