import { FunctionComponent } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { useBotStore, useUserStore } from "~/stores";
import { trackingAllPackages, trackingPackage } from "~/api/express";
import { CreatePackageMessageBody, TrackingResponse } from "~/types/express";
import TrackOrderTabs from "./TrackOrderTabs";
import ExpressProgress from "./ExpressProgress";
import { getConversationId } from "~/hooks/message/utils";
import { createMessage } from "~/api/chat";
import StepLoading from "../LoadingMessage/StepLoading";
import Tabs from "~/UIKit/Tabs";
import { RequestStatus } from "~/types/request";

import "./styles.scss";

export interface IExpressCardProps {
  packageTracking?: CreatePackageMessageBody["metadata"]["packageTracking"];
}

const ExpressCard: FunctionComponent<IExpressCardProps> = ({ packageTracking }) => {
  const [data, setData] = useState<
    | {
        trackInfo: TrackingResponse[] | null;
        orderInfo?: { fulfillmentStatus: string; orderStatusUrl: string };
      }
    | { error: any }
  >({ trackInfo: null });
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Initial);
  const { info } = useUserStore();
  const { getBotCode } = useBotStore();
  const formValuesRef = useRef<any>(packageTracking);

  const handleSubmit = async (values: any, skipPersist?: boolean) => {
    formValuesRef.current = values;
    const cloneValues = { ...values };
    try {
      setStatus(RequestStatus.Loading);
      const botCode = getBotCode();
      const res = await trackingAllPackages({
        ...values,
        ...info,
        botCode,
      });

      if (res) {
        setData(res);
        setStatus(RequestStatus.Success);
      } else {
        setStatus(RequestStatus.Fail);
        return;
      }

      if (skipPersist) {
        return;
      }

      const conversationId = await getConversationId(botCode);

      createMessage(conversationId, botCode, {
        ...info,
        content: "",
        messageFrom: "Bot",
        type: "PackageTracking",
        conversationId,
        metadata: { packageTracking: cloneValues },
      });
    } catch (error: any) {
      setStatus(RequestStatus.Fail);
      setData(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (packageTracking) {
      handleSubmit(packageTracking, true);
    }
  }, []);

  const renderContent = () => {
    if (status === RequestStatus.Loading) {
      return (
        <div className="tp-express-card-loading">
          <StepLoading size="large" />
        </div>
      );
    }

    if (status === RequestStatus.Success) {
      if ("trackInfo" in data) {
        if (data.trackInfo) {
          return data.trackInfo.length > 1 ? (
            <Tabs
              items={data.trackInfo.map((item, index) => ({
                key: `${index}`,
                label: `Package #${index + 1}`,
                children: <ExpressProgress data={item} />,
              }))}
            />
          ) : (
            <ExpressProgress data={data.trackInfo[0]} />
          );
        } else {
          return (
            <div>
              <div>
                Order {formValuesRef.current?.orderNumber} was {data.orderInfo?.fulfillmentStatus}.
              </div>
              <div className="tp-express-card-track-404">
                <div>You can view your full order details here:</div>
                <a href={data.orderInfo?.orderStatusUrl} target="_blank">
                  {data.orderInfo?.orderStatusUrl}
                </a>
              </div>
              <div>
                If you have any additional questions about your order, feel free to message us.
              </div>
            </div>
          );
        }
      }
    }

    if (status === RequestStatus.Fail) {
      if ("error" in data) {
        if (data.error.code === "tracking_number_not_found") {
          return <div className="tp-express-card-error-msg">{data.error.message}</div>;
        }
      }
      return (
        <div className="tp-express-card-error-msg">
          Order not found. Please verify the order number.
        </div>
      );
    }

    return <TrackOrderTabs onSubmit={handleSubmit} />;
  };

  return <div className="tp-express-card">{renderContent()}</div>;
};

export default ExpressCard;
