import BottomCoupon from "./Bottom";
import FullScreenCoupon from "./FullScreen";

const CouponCard = () => {
  return (
    <>
      <FullScreenCoupon />
      <BottomCoupon />
    </>
  );
};

export default CouponCard;
