import { FunctionComponent } from "preact";
import Form from "~/UIKit/Form";
import Input from "~/UIKit/Input";
import UIKitButton from "~/UIKit/Button";
import "./index.scss";

interface EmailCollectionProps {
  usageInstruction: string;
  onSubmit: (values: any) => void;
}

const EmailCollection: FunctionComponent<EmailCollectionProps> = ({
  usageInstruction,
  onSubmit,
}) => {
  const [form] = Form.useForm();

  return (
    <div className="coupon-bot-email-collection">
      <div className="usage-title">Instructions</div>
      <div className="usage-instruction">{usageInstruction}</div>
      <div className="email-form-container">
        <span style={{ color: "red" }}>*</span>
        <span style={{ color: "#262626" }}>Please enter your email</span>
        <Form form={form} onFinish={onSubmit}>
          <Form.Item name="email" rules={[{ required: true, message: "Please input your email" }]}>
            <Input placeholder="Support@targetpilot.ai" />
          </Form.Item>
          <UIKitButton htmlType="submit">Submit</UIKitButton>
        </Form>
      </div>
    </div>
  );
};

export default EmailCollection;
