import { CompanionCardType, PopConfigType, RetentionConfigType } from "~/types/shoppingCompanion";
import closeSvg from "~/assets/icons/coupon_close.svg?react";
import { createPortal, useCallback } from "preact/compat";
import "./index.scss";
import Icon, { CheckCircleOutlined } from "@ant-design/icons";
import { useCouponCard } from "~/hooks/useCouponCard";
import type { JSX } from "preact";

const BottomCoupon = (): JSX.Element | null => {
  const {
    brandColor,
    couponCompanion,
    currentCoupon,
    isVisible,
    handleHide,
    requiresEmailCollection,
    isCopied,
    handleCouponAction,
    email,
    setEmail,
    emailSubmitted,
    loading: emailSubmissionLoading,
  } = useCouponCard(CompanionCardType.Bottom);

  const retentionConfigType = couponCompanion?.retentionConfig;

  if (!isVisible || !currentCoupon) return null;

  const handleEmailChange = useCallback((e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    setEmail((e.target as HTMLInputElement).value);
  }, []);

  return createPortal(
    <div className="global-bottom-coupon">
      {retentionConfigType?.type === RetentionConfigType.UserAction && (
        <div className="close-button" onClick={handleHide}>
          <Icon component={closeSvg} />
        </div>
      )}

      <div className="coupon-container">
        <div className="coupon-content">
          <div>
            <div className="promotion-text">
              {couponCompanion?.popConfig?.type === PopConfigType.Coupon
                ? couponCompanion.popConfig.config.eventName
                : ""}
              <span className="emoji">🎉</span>
            </div>

            <div className="promotion-description">
              Copy your exclusive discount code and enter it at checkout to enjoy the discount!{" "}
              {currentCoupon.expiredTime !== "Never"
                ? `Valid until ${currentCoupon.expiredTime}`
                : "No Expiration"}
              .
            </div>
          </div>
          <div className="divider"></div>
          {requiresEmailCollection && !emailSubmitted ? (
            <div className="coupon-code-container">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email"
                className="coupon-code email-input"
              />
              <div
                className="copy-button"
                onClick={handleCouponAction}
                style={{ backgroundColor: brandColor }}
              >
                {emailSubmissionLoading ? "..." : "Claim Code"}
              </div>
            </div>
          ) : (
            <div className="coupon-code-container">
              <div className="coupon-code">{currentCoupon.code}</div>
              <div
                className="copy-button"
                onClick={handleCouponAction}
                style={{ backgroundColor: brandColor }}
              >
                {isCopied ? <CheckCircleOutlined /> : "Copy Code"}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default BottomCoupon;
