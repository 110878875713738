import { Product } from "~/types/product";
import request from "~/utils/request";

export const getRecommendProducts = (
  params: {
    tenantId: string;
    tenantUnitId: string;
    botCode: string;
  },
  data: { productRecLimit: number; productRecType: string; productRecSimProductId?: string }
): Promise<{ products: Product[] }> =>
  request({
    url: "/recommendations/products",
    method: "POST",
    params,
    data,
  });

export const getProductDetail = (params: {
  tenantId: string;
  tenantUnitId: string;
  botCode: string;
  productIds: string[];
}): Promise<Product[]> =>
  request({
    url: "/products",
    params,
  });
