import { createPortal, useState, useCallback } from "preact/compat";
import closeSvg from "~/assets/icons/close.svg?react";
import "./index.scss";
import Icon, { CheckCircleOutlined } from "@ant-design/icons";
import { CompanionCardType, PopConfigType } from "~/types/shoppingCompanion";
import { useCouponCard } from "~/hooks/useCouponCard";
import type { JSX } from "preact";

const FullScreenCoupon = (): JSX.Element | null => {
  const {
    brandColor,
    couponCompanion,
    currentCoupon,
    isVisible,
    handleHide,
    isCopied,
    handleCouponAction,
    requiresEmailCollection,
    email,
    setEmail,
    emailSubmitted,
    loading: emailSubmissionLoading,
  } = useCouponCard(CompanionCardType.FullScreen);

  if (!isVisible) return null;

  const handleEmailChange = useCallback((e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    setEmail((e.target as HTMLInputElement).value);
  }, []);

  return createPortal(
    <div className="global-fullscreen-coupon">
      <div className="coupon-modal">
        <button className="close-button" onClick={handleHide}>
          <Icon component={closeSvg} />
        </button>

        <div className="coupon-content">
          <div className="promotion-title">
            {couponCompanion?.popConfig?.type === PopConfigType.Coupon
              ? couponCompanion.popConfig.config.eventName
              : ""}
            <span className="emoji">🎉</span>
          </div>

          <div className="promotion-description">
            {requiresEmailCollection
              ? "Enter your email to claim your exclusive discount code!"
              : "Copy your exclusive discount code and enter it at checkout to enjoy the discount!"}
          </div>

          {requiresEmailCollection && !emailSubmitted ? (
            <div className="coupon-code-container">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email"
                className="coupon-code email-input"
              />
              <div
                className="copy-button"
                onClick={handleCouponAction}
                style={{ backgroundColor: brandColor }}
              >
                {emailSubmissionLoading ? "..." : "Claim Code"}
              </div>
            </div>
          ) : (
            <div className="coupon-code-container">
              <div className="coupon-code">{currentCoupon?.code}</div>
              <div
                className="copy-button"
                onClick={handleCouponAction}
                style={{ backgroundColor: brandColor }}
              >
                {isCopied ? <CheckCircleOutlined /> : "Copy"}
              </div>
            </div>
          )}

          <p className="expiry-date">
            {currentCoupon?.expiredTime !== "Never"
              ? `Valid until ${currentCoupon?.expiredTime}`
              : "No Expiration"}
          </p>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default FullScreenCoupon;
