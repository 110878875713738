import {
  CompanionCardType,
  DiscountCode,
  PopConfigType,
  ShoppingCompanion,
} from "~/types/shoppingCompanion";

export const getCouponCompanionByPosition = (
  companions: ShoppingCompanion[] | undefined | null,
  position: CompanionCardType
) => {
  return companions?.find(
    (companion) =>
      companion.popConfig.type === PopConfigType.Coupon &&
      companion.styleConfig?.cardType === position
  );
};

// Function to find the first selected active code
export const findFirstSelectedActiveCode = (codesArray: DiscountCode[]): DiscountCode | null => {
  return codesArray.find((item) => item.selected === true && item.status === "active") || null;
};
export const isProductCompanion = (companion?: ShoppingCompanion) => {
  return companion?.popConfig.type === PopConfigType.ProductRecommendation;
};

export const isTextCompanion = (companion?: ShoppingCompanion) => {
  return companion?.popConfig.type === PopConfigType.Text;
};
