export enum TriggerConfigType {
  TimeToStay = "timeToStay",
  ScrollPercentage = "scrollPercentage",
  Leave = "leave",
}

export enum RetentionConfigType {
  AutoHide = "autoHide",
  UserAction = "userAction",
}

export enum PopConfigType {
  Coupon = "coupon",
  ProductRecommendation = "productRecommendation",
  Text = "text",
}

export enum ProductRecommendationType {
  SelectedProduct = "selectedProduct",
  NewArrival = "newArrival",
  BestSeller = "bestSeller",
  RelatedProducts = "relatedProducts",
}

export enum CompanionCardType {
  Row = "row",
  Column = "column",
  Featured = "featured",
  FullScreen = "fullScreen",
  Bottom = "bottom",
  BotArea = "botArea",
}

// types/trigger.ts
export type TimeToStayTrigger = {
  type: TriggerConfigType.TimeToStay;
  config: {
    staySeconds: number;
  };
};

export type ScrollPercentageTrigger = {
  type: TriggerConfigType.ScrollPercentage;
  config: {
    scrollPercentage: number;
  };
};

export type LeaveTrigger = {
  type: TriggerConfigType.Leave;
  config?: object;
};

export type TriggerConfig = TimeToStayTrigger | ScrollPercentageTrigger | LeaveTrigger;

export type DiscountCode = {
  code: string;
  expiredTime: string;
  status: "active" | string;
  selected: boolean;
};

type LeadsConfig = {
  collectEmail: boolean;
  description: string;
};

export enum MessageType {
  Custom = "custom",
  QuickQuestion = "quickQuestion",
}

type PopConfig =
  | {
      type: PopConfigType.Text;
      config:
        | {
            messageType: MessageType.Custom;
            questions: [
              {
                title: string;
                content: string;
              },
            ];
          }
        | {
            messageType: MessageType.QuickQuestion;
            questions: [
              {
                order: number;
                content: string;
              },
            ];
          };
    }
  | {
      type: PopConfigType.Coupon;
      config: {
        discountCodes: DiscountCode[];
        eventName: string;
        leads: LeadsConfig;
        usageInstructions: string;
      };
    }
  | {
      type: PopConfigType.ProductRecommendation;
      config:
        | {
            productIds: string[];
            recommendationType: ProductRecommendationType.SelectedProduct;
          }
        | {
            recommendationType: ProductRecommendationType.NewArrival;
          }
        | {
            recommendationType: ProductRecommendationType.BestSeller;
          }
        | { recommendationType: ProductRecommendationType.RelatedProducts };
    };

type RetentionConfig = {
  type: RetentionConfigType;
  config: {
    hideAfterSeconds: number;
  };
};

type StyleConfig = {
  backgroundColor?: string;
  cardType?: string;
};

enum ShoppingCompanionType {
  BuiltIn = "builtIn",
  Customize = "customize",
}

export type ShoppingCompanion = {
  id: string;
  bgPicUrl: string | null;
  createdTime: string;
  popConfig: PopConfig;
  retentionConfig: RetentionConfig;
  status: "active" | string;
  styleConfig: StyleConfig;
  tenantId: string;
  tenantUnitId: string;
  triggerConfig: TriggerConfig;
  type: ShoppingCompanionType;
  updatedTime: string;
};

export enum CouponDisplayPosition {
  InBot = "inBot",
  OutSideBot = "outSideBot",
}

export interface createCompanionCouponMessageBody {
  tenantId: string;
  tenantUnitId: string;
  agentId: string;
  conversationId: string;
  messageFrom?: string;
  content: string;
  text?: string;
  type: "EmailCollection" | "Coupon" | "text";
  isBot?: boolean;
  metadata: {
    resource?: {
      coupon: {
        showCode: boolean;
      };
    };
  };
}
