import { usePrevious } from "ahooks";
import { useEffect, useImperativeHandle, useRef } from "preact/hooks";
import { type Ref } from "preact/compat";
import { useBotStore } from "~/stores";
import { BotConfigProps } from "~/utils/widgetConfig";
import { isUndefined } from "lodash-es";

export interface CustomMethods {
  toggleVisibility?: () => void;
  resetBotPosition?: () => void;
}

export default function useCustomElement(
  props: BotConfigProps & {
    botLoading: boolean;
    rootRef: Ref<CustomMethods>;
  }
) {
  const { code, eventToDocument, botLoading, rootRef } = props;

  const shadowRoot = useRef<HTMLElement>();

  useEffect(() => {
    try {
      shadowRoot.current = document.querySelector(
        `target-pilot-bot[code='${code}']`
      ) as HTMLElement;
    } catch (error) {
      //
    }
    // change host z-index
    if (shadowRoot.current) {
      shadowRoot.current.style.zIndex = "2147483647";
    }
  }, [code]);

  const {
    integrationConfig,
    botVisibleInWidgetMode,
    toggleBotVisibilityInWidgetMode,
    resetBotVisibleInWidgetMode,
    resetBotPosition,
  } = useBotStore();
  const popupHidden = isUndefined(integrationConfig.isWidget)
    ? true
    : Boolean(integrationConfig.isWidget && !botVisibleInWidgetMode);
  const prevBotVisibleInWidgetMode = usePrevious(botVisibleInWidgetMode);

  const dispatchCustomToggleEvent = (visible: boolean) => {
    if (!shadowRoot.current) return;
    let eventRootElement: HTMLElement | Document = shadowRoot.current;
    let eventName = "toggleBotPopup";

    if (eventToDocument) {
      eventRootElement = document;
      eventName = `${code}_toggleBotPopup`;
    }
    eventRootElement.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true,
        detail: { visible: visible },
      })
    );
  };
  // dispatch custom toggle event when visibility change
  useEffect(() => {
    if (integrationConfig.isWidget) {
      if (botLoading) {
        resetBotVisibleInWidgetMode();
        dispatchCustomToggleEvent(false);
        return;
      }
      if (botVisibleInWidgetMode !== prevBotVisibleInWidgetMode && !botLoading) {
        dispatchCustomToggleEvent(Boolean(botVisibleInWidgetMode));
      }
    }
  }, [botLoading, integrationConfig.isWidget, botVisibleInWidgetMode, prevBotVisibleInWidgetMode]);
  // expose methods
  useImperativeHandle(rootRef, () => ({
    toggleVisibility() {
      return toggleBotVisibilityInWidgetMode?.();
    },

    resetBotPosition() {
      resetBotPosition();
    },
  }));

  return { popupHidden };
}
