import { useState } from "preact/hooks";

const useCopyToClipboard = (duration: number = 2000) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
    } finally {
      setTimeout(() => setIsCopied(false), duration);
    }
  };

  return { copyToClipboard, isCopied };
};

export default useCopyToClipboard;
